import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

export default class BlogCardBlogCardMicro extends React.Component {
  render() {
    const {
      featured_media,
      title,
      date,
      slug,
      tag,
      inTagPage,
      readtime,
    } = this.props;

    return (
      <div className="rounded bg-white overflow-hidden h-100 card">
        <Fade>
          <>
            <div className="row h-100">
              <div className="col-4 h-100">
                <div className="position-absolute text-primary right-0 small z-index-100 pl-2 pr-3 py-1 font-weight-bold bg-white rounded-bottom-right shadow-lg opacity-80">
                  <i className="far fa-clock mr-1 small" />
                  {readtime}min read
                </div>
                {featured_media && (
                  <Image
                    image={featured_media}
                    className="w-100 h-100 bg-light card-journal__image"
                    alt={title}
                  />
                )}
                {!featured_media && (
                  <div className="card-journal__image bg-primary" />
                )}
              </div>

              <div className="col-8 h-100 small pt-2 pr-4">
                <div className={`small text-right mt-1 mr-2`}>{date}</div>
                {!inTagPage && tag && (
                  <div className="h5 text-dark card-title text-black mb-0 small">
                    {tag}
                  </div>
                )}
                <Link className="text-primary small stretched-link" to={slug}>
                  <h2 className="h5 font-family-display card-title">
                    <span>{title}</span>
                  </h2>
                </Link>
              </div>
            </div>
          </>
        </Fade>
      </div>
    );
  }
}
