import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";

const Sidebar = ({
  leftBanner,
  leftImage,
  leftUrl,
  leftTitle,
  rightBanner,
  rightImage,
  rightUrl,
  rightTitle,
}) => {
  return (
    <aside className="journal_content__sidebar stick-lg-top pt-4 mt-lg-0">
      <div className="container position-relative">
        <div className="journal_content__sidebar_inner">
          <div className="socials d-block">
            <Link to={leftUrl}>
              <Image image={leftBanner} className="img-fluid d-none d-lg-block" alt={leftTitle} />
              <Image image={leftImage} className="img-fluid d-lg-none" alt={leftTitle} />
            </Link>
          </div>
        </div>
        <div className="journal_content__sidebar_inner_right d-none d-lg-block">
          <div className="socials d-block">
            <Link to={rightUrl}>
              <Image image={rightBanner} className="img-fluid d-none d-lg-block" alt={rightTitle} />
              <Image image={rightImage} className="img-fluid d-lg-none" alt={rightTitle} />
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
