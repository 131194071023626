import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";
import _ from "lodash";

const BlogHeader = ({
  title,
  excerpt,
  tags,
  date,
  author,
  bg_colour,
  bg_image,
  featured_media,
  textColour,
  readtime,
}) => (
  <header
    id="hero"
    className={`
      ${
        (bg_colour ? bg_colour : "bg-grad-main") +
        " hero hero_single pb-4 pb-lg-5 " +
        (bg_image ? "hero_single-withimage" : "")
      }
    `}
  >
    {bg_image && (
      <div className="hero_single_backgorund">
        <Image
          image={featured_media}
          className="position-absolute w-100 h-100"
        />
      </div>
    )}

    <div className="position-relative container pt-5 pt-lg-0">
      <div className="row justify-content-center">
        <div className="hero-intro col-lg-12 px-lg-0">
          <div className="bg-white negative-margin text-center pb-4 px-3 p-md-5 pt-3 pb-lg-2 rounded-top">
            {tags && (
              <div className={`journal_category small font-weight-bold mb-2`}>
                <Link
                  cover={`true`}
                  direction="right"
                  bg="#1F1A3C"
                  to={`/tags/${_.kebabCase(tags)}/`}
                >
                  <i className="far text-primary fa-books"></i> {tags}
                </Link>
              </div>
            )}
            <div className={`journal_date`}>{date}</div>
            <h1
              className={`h2 font-family-display journal_title ${textColour} `}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {readtime && (
              <div className={`journal_readtime mb-2 mb-lg-5`}>
                {readtime} min read
              </div>
            )}

            <p
              className="journal_lead lead mb-lg-4 text-center"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
            {author &&
              (author && author.name ? (
                <div className="journal_authortop">
                  <Image
                    image={author.featuredimage}
                    className="rounded-circle"
                    alt={author.title}
                  />

                  <p className="mb-0">
                    Written by <strong>{author.name}</strong> - {author.title}
                  </p>
                </div>
              ) : (
                <div className="journal_authortop">
                  <div className=" mb-4">
                    <span className={`${textColour} fa-stack p fa-2x`}>
                      <i className="fas fa-circle fa-stack-2x"></i>
                      <i className="far text-white fa-user fa-stack-1x"></i>
                    </span>
                  </div>
                  <p className="mb-0">
                    Written by <strong>{author}</strong>
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default BlogHeader;
