import React from "react";
import { Link } from "gatsby";
import BlogCard from "./BlogCard";
import BlogCardMicro from "./BlogCardMicro";

const BlogNext = ({ posts, title, link, linkText, background, mini }) => {
  return (
    <aside className={`blog-more section pt-5 ${background}`}>
      <h2 className="font-family-display text-center pt-5">{title}</h2>

      <div className="container-fluid negative-margin ">
        <div className="row justify-content-center bg-secondary mt-5">
          <div className="col-lg-10 py-5 ">
            <div className="row negative-margin_inner justify-content-center contained-xl">
              {posts.map(({ node: post }, index) => (
                <div key={post.id} className="col-md-6 col-lg-4 mb-4">
                  {!mini ? (
                    <BlogCard
                      featured_media={post.frontmatter.image}
                      title={post.frontmatter.title}
                      date={post.frontmatter.date}
                      excerpt={post.frontmatter.excerpt}
                      tag={post.frontmatter.tags}
                      slug={post.fields.slug}
                      readtime={post.timeToRead}
                    />
                  ) : (
                    <BlogCardMicro
                      featured_media={post.frontmatter.image}
                      title={post.frontmatter.title}
                      date={post.frontmatter.date}
                      excerpt={post.frontmatter.excerpt}
                      tag={post.frontmatter.tags}
                      slug={post.fields.slug}
                      readtime={post.timeToRead}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row justify-content-center pb-5 bg-secondary">
          <Link to={link} className="btn btn-outline-primary">
            {linkText}
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default BlogNext;
