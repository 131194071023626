import React, { useState, useEffect } from "react"; //useRef,
// import IframeResizer from "iframe-resizer-react";
import { Link } from "gatsby";

export const IFrameEmbed = ({ side_title, side_content, url }) => {
  // const params = location.search;

  const [urlParams, setUrlParams] = useState(``);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams) {
        setUrlParams(urlParams);
      }
    }
  }, []);

  const iFrameUrl = urlParams
    ? url.includes("?")
      ? url + "&" + urlParams
      : url + "?" + urlParams
    : url;

  // const iframeRef = useRef(null);
  // const [messageData, setMessageData] = useState();

  // const onResized = (data) => {
  //   // setMessageData(data)
  //   console.log(data);
  // };

  // const onMessage = (data) => {
  //   // setMessageData(data)
  //   iframeRef.current.sendMessage("Hello back from the parent page");
  // };
  return (
    <section id="iframe" className="container py-4 py-lg-5 ">
      <div className="row justify-content-center">
        <div className="col-11 bg-primary rounded py-5">
          <div className="row justify-content-center ">
            {side_title && (
              <div className="col-lg-8 col-xl-7 mb-4 mb-lg-5 text-center">
                <h2 className="mb-lg-3">{side_title}</h2>
                {side_content}
              </div>
            )}
            <div className={`col-12 col-lg-11 col-xl-10`}>
              <div className="text-center">
                <Link to={iFrameUrl} className="btn btn-white btn-lg">
                  Sign Up
                </Link>
                {/* <IframeResizer
              id="iframe-resizer"
              forwardRef={iframeRef}
              heightCalculationMethod="max"
              inPageLinks
              log
              autoResize={true}
              resizeFrom={("parent", "child")}
              onMessage={onMessage}
              onResized={onResized}
              className="shadow-lg overflow-hidden p-0"
              title={side_title}
              src={iFrameUrl}
              style={{
                width: "100%",
                minWidth: "100%",
                // height: "100vh",
                minHeight: "80vh",
                border: 0,
                transition: "0.3s ease all",
                overflow: "hidden",
              }}
            /> */}
                {/* <iframe
                  // forwardRef={iframeRef}
                  // heightCalculationMethod="max"
                  // inPageLinks
                  // log
                  // autoResize={true}
                  // resizeFrom={('parent', 'child')}
                  // onMessage={onMessage}
                  // onResized={onResized}
                  className="rounded-lg bg-primary overflow-hidden p-0"
                  frameBorder="0"
                  title={side_title}
                  src={url}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    height: "100vh",
                    maxHeight: "830px",
                    border: 0,
                    transition: "0.3s ease all",
                    overflow: "hidden",
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IFrameEmbed;
